import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./i18n"; // Import the i18n configuration
import Navbar from "./components/Navbar/Navbar";
import Homepage from "./pages/Homepage/Homepage";  
import ProjectDisplay from "./pages/Portfolio/ProjectDisplay/ProjectDisplay";

function App() {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    console.log("Current Language:", i18n.language);
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    console.log("Language changed to:", lng);
  };

  return (
    <div className="App">
      <Navbar lng={i18n.language} changeLanguage={changeLanguage} t={t} />
      <div>
        <Routes>
          {/* Dynamic routing with the ":page" param */}
          <Route path="/:page" element={<Homepage t={t} />} />
          {/* Specific routes for other pages */}
          <Route path="/" element={<Homepage t={t} />} />
          <Route path="/portfolio/:projectName" element={<ProjectDisplay lng={i18n.language} t={t}  />} /> 
        </Routes>
      </div>
    </div>
  );
}

export default App;