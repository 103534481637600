import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import projectData from './ProjectsData.json'
import styles from './LatestProject.module.css'
import openIcon from './Open.png'
import { FaLock } from 'react-icons/fa6'

const LatestProjects = () => {
  const navigate = useNavigate()
  const [shake, setShake] = useState(false)
  const passwordRef = useRef(null)

  const handleCardClick = (project) => {
    if (project.cover) {
      navigate(project.link)
    } else {
      setShake(true)
      passwordRef.current.focus()
      setTimeout(() => setShake(false), 500) // End shake effect after animation
    }
  }

  return (
    <div className={styles.projectsContainer}>
      <section className={styles.projectsCards}>
        {projectData.map((project, index) => (
          <div
            key={index}
            onClick={() => handleCardClick(project)}
            className={`${styles.projectCard} ${project.rightImage ? styles.rightImage : styles.leftImage}`}
          >
            <div className={styles.cardText}>
              <h3 className={styles.cardTitle}>
                {project.title}
                <img className={styles.cardIcon} src={openIcon} alt="Open" />
              </h3>
              <ul className={styles.tagsList}>
                {project.tags.map((tag, tagIndex) => (
                  <li key={tagIndex} className={styles.tagItem}>
                    {tag}
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.imageContainer}>
              <img className={styles.cardImage} src={project.imgSrc} alt={project.altText} />
              <div className={styles.overlay}>
                {!project.locked ? (
                  <img className={styles.projectLogo} src={project.cover} alt={`${project.title} logo`} />
                ) : (
                  <div className={styles.locked}>
                   
                    <form>
                      <label>
                      <div className={`${styles.lockIcon} ${shake ? styles.shake : ''}`}>
                      <FaLock size="2vh" color='#515151' />
                    </div>
                        Enter Password: <br />
                      </label>
                      <input type='password' ref={passwordRef} />
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  )
}

export default LatestProjects