import React, { useEffect, useState } from "react";
import styles from "./ProjectDisplay.module.css";
import { useParams } from "react-router-dom";
import Carousel from "./Carousel";
import { FaCheck } from "react-icons/fa";

const ProjectDisplay = ({ lng }) => {
  const { projectName } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadProjectData = async () => {
      try {
        const data = await import(`../Projects/${projectName}.json`);
        setProjectData(data.default);
      } catch (err) {
        console.error("Error loading project data:", err);
        setError(`Failed to load project data for ${projectName}`);
      } finally {
        setLoading(false);
      }
    };

    loadProjectData();
    window.scroll(0, 0); // Scroll to top on load
  }, [projectName, lng]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!projectData) return <div>No project data available</div>;

  const {
    title = "Untitled Project",
    overview = {},
    challenge_and_goals = {},
    process_and_approach = {},
    information_architecture = {},
    wireframes_and_prototypes = {},
    design_and_visual_language = {},
    development = {},
    testing_and_iteration = {},
    conclusion = {},
    site_map = {},
    user_flows = {}
  } = projectData;


  return (
    <div className={styles.mainContainer}>
      <div className={styles.projectDisplay}>
        
        {/* Project Title and Overview */}
        <section className={styles.projectSection}>
          <h1 className={styles.projectTitle}>{title}</h1>
          {overview.hero_image && (
            <img
              src={overview.hero_image.url}
              alt={overview.hero_image.description || "Hero image"}
              className={styles.heroImage}
            />
          )}
          <div className={styles.projectOverview}>
            <h3 className={styles.sectionTitle}>Overview</h3>
            <p>{overview.description || "No description available."}</p>
            <p><strong>Timeline:</strong> {overview.timeline || "N/A"}</p>
            <p><strong>Location:</strong> {overview.location || "N/A"}</p>
          </div>
        </section>
  

        {/* Process, User Research, Personas */}
        <section className={styles.projectSection}>
          <h3 className={styles.sectionTitle}>User Research and Understanding</h3>
          <p>{process_and_approach.research_and_user_understanding?.description || "No research description available."}</p>
          <ul className={styles.findingsList}>
            {process_and_approach.research_and_user_understanding?.key_findings ? (
              process_and_approach.research_and_user_understanding.key_findings.map(
                (finding, index) => <li className={styles.finding} key={index}><div><img className={styles.findingIcon} src={finding.image}/></div><div><p><strong>{finding.title}</strong><br/>{finding.description}</p></div></li>
              )
            ) : (
              <li>No key findings available.</li>
            )}
          </ul>
        </section>

        <section className={styles.projectSection}>
  <h3 className={styles.sectionTitle}>Personas</h3>
  <ul className={styles.personaCards}>
    {process_and_approach.personas ? (
      process_and_approach.personas.map((persona, index) => (
        <li className={styles.card} key={index}>
         
          <div className={styles.leftSection}>
            {persona.image && <img className={styles.personaAvatar} src={persona.image} alt={`Avatar of ${persona.name}`} />}
            
          </div>
          <div className={styles.rightSection}>
            <div className={styles.mainInfo}>
          <p><strong>{persona.name}</strong>, {persona.age} </p> 
         
            <p><strong>Occupation:</strong> {persona.occupation}</p> </div>
            <div className={styles.rows}>
            <div className={styles.infoRow}>
              <div className={styles.innerCard}>
            <p><strong>Challenges</strong> <br/> {persona.challenges}</p></div>
            <div className={styles.innerCard}>
            <p><strong>Motivations</strong> <br/> {persona.motivations}</p></div></div>
            <div className={styles.infoRow}>
            <div className={styles.innerCard}>
            <p><strong>Pain Points</strong> <br/> {persona.pain_points}</p> </div>
            <div className={styles.innerCard}>
            <p><strong>Tech Comfort Level</strong><br/> {persona.tech_comfort_level}</p> </div></div>
          </div></div>
        
        </li>
      ))
    ) : (
      <li>No personas available.</li>
    )}
  </ul>
</section>
<section className={styles.projectSection}>
          <h3 className={styles.sectionTitle}>Challenges</h3>
          <p>{challenge_and_goals.challenge || "No challenge description available."}</p>
        </section>
<section className={styles.projectSection}>
          <h3 className={styles.sectionTitle}>Goals</h3>
          <ul className={styles.goalsList}>
            {challenge_and_goals.goals ? (
              challenge_and_goals.goals.map((goal, index) => (
                <li className={styles.goal} key={index}>
                 <FaCheck color={challenge_and_goals.checkColor}/>
                  {goal.description}
                </li>
              ))
            ) : (
              <li>No goals available.</li>
            )}
          </ul>
        </section>

        <section className={styles.projectSection}>
          <h3 className={styles.sectionTitle}>Site Map</h3>
            {site_map.image ? (
               <img  className={styles.singleImageDisplay} src={site_map.image.url} alt={site_map.image.description}/>
            ) : (
              <p>No Site Map available.</p>
            )}
 
        </section>

        {/* User Flows */}
        <section className={styles.projectSection}>
  <h3 className={styles.sectionTitle}>User Flows</h3>
  {Array.isArray(user_flows) && user_flows.length > 0 ? (
    user_flows.map((flow, index) => (
      <div key={index} className={styles.flowContainer}>
        <h4>{flow.title}</h4>
        <p>{flow.description}</p>
        {Array.isArray(flow.images) && flow.images.length > 1 ? (
          <Carousel projectData={projectData} images={flow.images} />
        ) : (
          flow.images.length === 1 && (
            <img
              className={styles.singleImageDisplay}
              src={flow.images[0].url}
              alt={flow.images[0].description}
            />
          )
        )}
      </div>
    ))
  ) : (
    <p>No user flows available.</p>
  )}
</section>

        

        {/* Wireframes and Prototypes */}
        <section className={styles.projectSection}>
          {wireframes_and_prototypes.low_fidelity_wireframes && (
            <>
              <h3 className={styles.sectionTitle}>Low Fidelity Prototypes</h3>
              <img
                className={styles.singleImageDisplay}
                src={wireframes_and_prototypes.low_fidelity_wireframes.image.url}
                alt={wireframes_and_prototypes.low_fidelity_wireframes.image.description}
              />
              <p>{wireframes_and_prototypes.low_fidelity_wireframes.description}</p>
            </>
          )}
          {wireframes_and_prototypes.high_fidelity_prototypes && (
            <>
              <h3 className={styles.sectionTitle}>High Fidelity Prototypes</h3>
              <img
                className={styles.singleImageDisplay}
                src={wireframes_and_prototypes.high_fidelity_prototypes.image.url}
                alt={wireframes_and_prototypes.high_fidelity_prototypes.image.description}
              />
              <p>{wireframes_and_prototypes.high_fidelity_prototypes.description}</p>
            </>
          )}
        </section>

        {/* Visual Design */}
        <section className={styles.projectSection}>
          <h3 className={styles.sectionTitle}>Visual Design</h3>
          {design_and_visual_language.design_language && (
            <p>{design_and_visual_language.design_language.description}</p>
          )}
          {design_and_visual_language.component_design && (
            <>
              <img
               className={styles.singleImageDisplay}
                src={design_and_visual_language.component_design.images[0].url}
                alt={design_and_visual_language.component_design.images[0].description}
              />
              <p>{design_and_visual_language.component_design.description}</p>
            </>
          )}
        </section>

        {/* Development */}
        <section className={styles.projectSection}>
          {development.front_end_development && (
            <>
              <h3 className={styles.sectionTitle}>Front-End Development</h3>
              <p>{development.front_end_development.description}</p>
            </>
          )}
          {development.back_end_development && (
            <>
              <h3 className={styles.sectionTitle}>Back-End Development</h3>
              <p>{development.back_end_development.description}</p>
            </>
          )}
        </section>

        {/* User Testing */}
        <section className={styles.projectSection}>
          <h3 className={styles.sectionTitle}>User Testing</h3>
          {testing_and_iteration.user_testing && (
            <>
              <img
                className={styles.singleImageDisplay}
                src={testing_and_iteration.user_testing.image.url}
                alt={testing_and_iteration.user_testing.image.description}
              />
              <p>{testing_and_iteration.user_testing.description}</p>
            </>
          )}
        </section>

        {/* Conclusion */}
        <section className={styles.projectSection}>
          <h3 className={styles.sectionTitle}>Conclusion</h3>
          <p>{conclusion.description || "No conclusion available."}</p>
        </section>
      </div>
    </div>
  );
};

export default ProjectDisplay;