import React, { useEffect, useState } from 'react';
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import styles from "./ProjectDisplay.module.css";

const Carousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    setCurrentImageIndex(0); // Reset image index on data change
  }, [images]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => Math.min(prevIndex + 1, images.length - 1));
  };

  if (!images || images.length === 0) {
    return <p>No images available.</p>; // Handle case where images are undefined or empty
  }

  return (
    <div className={styles.carousel}>
      {images.map((image, index) => (
        <div key={index}>
          <img
            className={`${styles.imageDisplay} ${currentImageIndex === index ? styles.active : ''}`}
            src={image.url}
            alt={image.description || 'Image'}
          />
        </div>
      ))}
      <div className={styles.buttonContainer}>
        <button
          className={styles.carouselButton}
          onClick={handlePrevImage}
          disabled={currentImageIndex === 0}
        >
          <FaCaretLeft />
        </button>
        <button
          className={styles.carouselButton}
          onClick={handleNextImage}
          disabled={currentImageIndex === images.length - 1}
        >
          <FaCaretRight />
        </button>
      </div>
      <p className={styles.imageDescription}>{images[currentImageIndex].description}</p>
    </div>
  );
};

export default Carousel;