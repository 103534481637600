import React from "react";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.css"
import flagPt from "../../assets/flag_pt.png";
import flagEn from "../../assets/flag_en.png";

const Navbar = ({handleContact, changeLanguage, lng, t }) => {
  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    changeLanguage(selectedLanguage);
  };

 
  return (
    <nav className={styles.navbar}>
      <ul>
        <li onClick={handleContact}><Link to="/">{t('home')}</Link></li>
        <li onClick={handleContact}><Link to="/contact">{t('contact')}</Link></li>
        <li>  <div className={styles.languageContainer}>
                 {lng == 'pt' ? (<img src={flagPt} alt="Brazilian Flag"/>) : (<img src={flagEn} alt="United States Flag"/>) }
        <div className={styles.languageDropdown}>
          <select value={lng} onChange={handleLanguageChange}>
            {lng === 'pt' ? (
              <><option value="pt">Português</option>
                <option value="en">English</option>
                
              </>
            ) : (
              <><option value="en">English</option>
                <option value="pt">Português</option>
                
              </>
            )}
          </select></div></div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;